import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'TStoryCard',
  props: {
    storyList: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const currIdx = ref(0);
    const lookPrevImg = () => {
      if (currIdx.value <= 0) {
        currIdx.value = props.storyList.length - 1;
        return;
      }
      currIdx.value--;
    };
    const lookNextImg = () => {
      if (currIdx.value >= props.storyList.length - 1) {
        currIdx.value = 0;
        return;
      }
      currIdx.value++;
    };
    function handleClick(index) {
      currIdx.value = index;
    }
    return {
      currIdx,
      lookPrevImg,
      lookNextImg,
      handleClick
    };
  }
});