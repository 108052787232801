import { defineComponent, ref } from 'vue';
import { SOLUTION_BASE_INFO } from '@/views/Solution/config';
export default defineComponent({
  name: 'TMoreService',
  props: {
    types: {
      type: Array,
      required: true
    }
  },
  components: {},
  setup(props) {
    const moreService = ref({});
    props.types.map(type => {
      moreService.value[type] = SOLUTION_BASE_INFO[type];
    });
    return {
      moreService
    };
  }
});