/* eslint-disable vue/max-len */
/* eslint-disable max-len */
import commonServiceIcon1 from './images/common_service_1.png';
import commonServiceIcon2 from './images/common_service_2.png';
import commonServiceIcon3 from './images/common_service_3.png';
import commonServiceIcon4 from './images/common_service_4.png';
import commonServiceIcon5 from './images/common_service_5.png';
import commonServiceIcon6 from './images/common_service_6.png';
import seniorFacebook from './images/senior_facebook.png';
import seniorGoogle from './images/senior_google.png';
import seniorLinked from './images/senior_linkedin.png';

export enum ISolutionTypeEnum {
    common = 'common',
    senior = 'senior',
    station = 'station',
}
type ISolutionKey = 'common' | 'senior' | 'station';

interface IBaseTagItem {
    title: string;
    desc: string[];
}

interface IBasechildItem {
    path: string;
    text: string;
}

interface ISolutionBase {
    title: string;
    subTitle: string;
    tags: IBaseTagItem[];
    feature: string[];
    path: string;
    child: IBasechildItem[];
}

export type ISolutionBaseInfo = {
    [key in ISolutionKey]: ISolutionBase;
};

export const SOLUTION_BASE_INFO = {
    [ISolutionTypeEnum.common]: {
        title: '起航版',
        subTitle: '一站式数智外贸工作台 ，企业出海管家',
        tags: [
            {
                title: '10+',
                desc: ['近10年探索海外市场', '服务过亿用户'],
            },
            {
                title: '20+',
                desc: ['20多年大数据和人工', '智能技术沉淀'],
            },
            {
                title: '220+',
                desc: ['持续分析研究全球220+个', '国家贸易和企业数据'],
            },
        ],
        feature: ['高效开发客户', '专属流量沉淀', '多维数据护航'],
        path: '/solution/common',
        child: [
            {
                path: '/solution/detail/3000/4000',
                text: '购买起航版',
            },
        ],
    },
    [ISolutionTypeEnum.senior]: {
        title: '海外广告',
        subTitle: '海外广告营销服务 – 专注外贸增量',
        tags: [
            {
                title: '10+',
                desc: ['近10年探索海外市场', '服务过亿用户'],
            },
            {
                title: '20+',
                desc: ['20多年大数据和人工', '智能技术沉淀'],
            },
            {
                title: '220+',
                desc: ['持续分析研究全球220+个', '国家贸易和企业数据'],
            },
        ],
        feature: ['定制化广告投放策略', '多元化组合投放渠道', '精细化流量运营'],
        path: '/solution/senior',
        child: [
            {
                path: '/solution/detail/5000/4001',
                text: 'Google营销',
            },
            {
                path: '/solution/detail/5003/4007',
                text: 'LinkedIn营销',
            },
            {
                path: '/solution/detail/5001/4004',
                text: 'Facebook营销',
            },
        ],
    },
    [ISolutionTypeEnum.station]: {
        title: '独立站版',
        subTitle: '专注独立站，快速搭建企业专属网站',
        tags: [
            {
                title: '10+',
                desc: ['近10年探索海外市场', '服务过亿用户'],
            },
            {
                title: '20+',
                desc: ['20多年大数据和人工', '智能技术沉淀'],
            },
            {
                title: '220+',
                desc: ['持续分析研究全球220+个', '国家贸易和企业数据'],
            },
        ],
        feature: ['专注独立站', '多模板建站', '站点数据统计'],
        path: '/solution/station',
        child: [
            {
                path: '/solution/detail/8000/8003',
                text: '购买独立站版',
            },
        ],
    },
};

/**
 * 服务卡片配置
 */
interface IPriceApi {
    desc?: string;
    value?: string;
    unit?: string;
}

export interface IListItemApi {
    spuid?: number;
    skuid?: number;
    title: string;
    icon?: string;
    price?: IPriceApi;
    descList: string[];
}

export interface IServiceCardConfigApi {
    title: string;
    desc?: string;
    spuid?: number;
    skuid?: number;
    price: IPriceApi;
    specialPrice?: IPriceApi;
    list: IListItemApi[];
};

export type ISolutionServiceCard = {
    [key in ISolutionKey]: IServiceCardConfigApi;
};

export const SERVICE_CARD_CONFIG: ISolutionServiceCard = {
    // 起航版
    [ISolutionTypeEnum.common]: {
        // skuid 4000 spuid 3000
        title: '起航版',
        desc: '商家后台赋能业务',
        spuid: 3000,
        skuid: 4000,
        price: {
            value: '¥ 39,800',
            unit: '年',
        },
        list: [
            {
                spuid: 3000,
                skuid: 4000,
                icon: commonServiceIcon1,
                title: '线索管理-CRM',
                descList: ['不同来源客户资源统一管理', '针对不同客户类型分组、分策略开发', '客户联系记录和电子邮件跟进记录统一管理', '根据客户类型定向精准广告和电子邮件推广'],
            },
            {
                spuid: 3000,
                skuid: 4000,
                icon: commonServiceIcon2,
                title: '邮件营销-EDM',
                descList: ['专业海外邮箱服务器', '全年海量电子邮件发送数', '邮件发送和打开数据实时掌控', '专业技术支持保障发送成功率'],
            },
            {
                spuid: 3000,
                skuid: 4000,
                icon: commonServiceIcon3,
                title: '数据参谋',
                descList: ['覆盖全部41个国际分类行业', '触达全球220多个国家进出口双向数据', '涵盖1700多万海外采购商最新数据（每周更新）', '每周可查询多达500个海外采购联系人方式'],
            },
            {
                spuid: 3000,
                skuid: 4000,
                icon: commonServiceIcon4,
                title: '多帐号管理',
                descList: ['多帐号实时管理后台', '账号权限可控', '为多账号分配不同角色', '百度账号即可作为子帐号使用'],
            },
            {
                spuid: 3000,
                skuid: 4000,
                icon: commonServiceIcon5,
                title: '商品管理',
                descList: ['创建多达20个产品分类', '无限量上传产品', '产品数据官网同步', '产品页面自动SEO优化'],
            },
            {
                spuid: 3000,
                skuid: 4000,
                icon: commonServiceIcon6,
                title: '站点管理',
                descList: ['全球10大搜索引擎自然排名自动优化', '国际7大地区服务器部署和访问速度优化',
                    '支持丰富的语言设置，根据客户语言偏好自动翻译', '配合国际最新营销技术的“落地页”自定义设置'],
            },
        ],
    },
    // 扬帆版
    [ISolutionTypeEnum.senior]: {
        title: '扬帆版',
        desc: '1v1专属广告经理',
        price: {
            value: '',
            unit: '',
        },
        list: [
            {
                spuid: 5000,
                skuid: 4001,
                title: 'Google营销',
                price: {
                    value: '¥ 10,000',
                    unit: '月',
                },
                icon: seniorGoogle,
                descList: [
                    '全球使用率最高的外贸推广渠道；根据调查显示，71%的海外采购者的采购决策始于网上搜索。出海商家会在采购搜索阶段用谷歌广告这一重要手段去占领采购者的心智。谷歌作为全球最大的搜索引擎，也是外贸企业在广告投放尝试中最常用的平台之一。',
                ],
            },
            {
                // sku：4007 4008 4009 spu：5003
                spuid: 5003,
                skuid: 4007,
                title: 'LinkedIn营销',
                price: {
                    value: '¥ 30,000',
                    unit: '月',
                },
                icon: seniorLinked,
                descList: [
                    '外贸B2B采购决策者的职场社交平台；领英作为全球最大的职场社交平台，拥有800万企业高管、6200万企业决策者和8600万资深决策影响人。其用户常年活跃。作为出海企业，广告投放最重要的目标就是影响采购决策者，而领英的信息流广告、站内信广告、专属增粉广告等广告解决方案可以充分满足这个需求。',
                ],
            },
            {
                // sku：4004 4005 4006 spu：5001
                spuid: 5001,
                skuid: 4004,
                title: 'Facebook营销',
                price: {
                    value: '¥ 10,000',
                    unit: '月',
                },
                icon: seniorFacebook,
                descList: [
                    '65%B2B营销人士每日使用的推广平台；Facebook相比其他媒体平台，拥有投放精准度更高，投资回报率更优等特点。外贸企业利用Facebook可以进行多种类型的广告投放：包括品牌曝光、网站引流、询盘量提高等。如果您在预算较低的情况下想要选择一款社交媒体的广告投放平台，那么Facebook将是首选。',
                ],
            },
        ],
    },
    [ISolutionTypeEnum.station]: {
        // skuid 8003 spuid 8000
        title: '独立站版',
        desc: '商家后台赋能业务',
        spuid: 8000,
        skuid: 8003,
        price: {
            value: '¥ 39,800',
            unit: '年',
        },
        list: [
            {
                spuid: 8000,
                skuid: 8003,
                icon: commonServiceIcon6,
                title: '独立站',
                descList: ['全球10大搜索引擎自然排名自动优化', '国际7大地区服务器部署和访问速度优化',
                    '支持丰富的语言设置，根据客户语言偏好自动翻译', '支持多种组建以及模板进行建站'],
            },
            {
                spuid: 8000,
                skuid: 8003,
                icon: commonServiceIcon1,
                title: '线索管理',
                descList: ['不同来源客户资源统一管理', '针对不同客户类型分组、分策略开发', '支持填写线索备注，随时了解线索进度', '支持多种标签标识不同线索'],
            },
            {
                spuid: 8000,
                skuid: 8003,
                icon: commonServiceIcon5,
                title: '商品管理',
                descList: ['创建多达20个产品分类', '无限量上传产品', '产品数据官网同步', '产品页面自动SEO优化'],
            },
        ],
    },
};
/* eslint-disable max-len */
/* eslint-disable vue/max-len */