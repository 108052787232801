import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { SOLUTION_BASE_INFO } from '@/views/Solution/config';
/* eslint-disable-next-line */
export default defineComponent({
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const router = useRouter();
    return {
      router,
      baseInfo: (SOLUTION_BASE_INFO === null || SOLUTION_BASE_INFO === void 0 ? void 0 : SOLUTION_BASE_INFO[props.type]) || {}
    };
  }
});