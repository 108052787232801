import "core-js/modules/es.array.push.js";
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
export default defineComponent({
  name: 'TSolutionButton',
  props: {
    text: {
      type: String,
      default: '预约一对一咨询'
    },
    route: {
      type: Object,
      default: () => ({
        path: '/',
        hash: '#joinus'
      })
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    /**
     * height -> large: 48px, medium: 40px
     */
    size: {
      type: String,
      default: 'large'
    }
  },
  setup(props) {
    const router = useRouter();
    function jump() {
      if (!props.route) {
        return;
      }
      if (typeof props.route === 'string') {
        window.open(props.route, '_blank');
      } else {
        router.push(props.route);
      }
    }
    ;
    return {
      jump
    };
  }
});