/* eslint-disable max-len, vue/max-len */

import storyImg1 from '@/views/Solution/images/story/person1@2x.png';
import storySmallImg1 from '@/views/Solution/images/story/person1-small@2x.png';
import storyImg2 from '@/views/Solution/images/story/person2@2x.png';
import storySmallImg2 from '@/views/Solution/images/story/person2-small@2x.png';
import storyImg3 from '@/views/Solution/images/story/person3@2x.png';
import storySmallImg3 from '@/views/Solution/images/story/person3-small@2x.png';
import storyImg4 from '@/views/Solution/images/story/person4@2x.png';
import storySmallImg4 from '@/views/Solution/images/story/person4-small@2x.png';

export const SolutionAlias = [
    {
        title: '96国进出口数据',
        desc: '覆盖全部品类和海关编码',
        common: true,
        flagship: true,
    }, {
        title: '1700多万海外采购商名录',
        desc: '包含进出口数据并每周更新',
        common: true,
        flagship: true,
    }, {
        title: '查询企业联系人和联系方式',
        desc: '每周可查询500个联系人',
        common: true,
        flagship: true,
    }, {
        title: '专业外贸营销建站',
        desc: '产品和宣传资料便捷管理',
        common: true,
        flagship: true,
    }, {
        title: '国际规范',
        desc: '更符合全球买家浏览习惯',
        common: true,
        flagship: true,
    }, {
        title: '23种多语言网页',
        desc: '根据访客语言偏好智能展现',
        common: true,
        flagship: true,
    }, {
        title: '自然搜索排名优化',
        desc: '面向全球10大搜索引擎',
        common: true,
        flagship: true,
    }, {
        title: '营销落地页',
        desc: '为网站访客推送定制内容',
        common: true,
        flagship: true,
    }, {
        title: '海外访问速度优化',
        desc: '全球7大地区服务器部署',
        common: true,
        flagship: true,
    }, {
        title: '5个企业邮箱地址',
        desc: '全年多达12万封',
        common: true,
        flagship: true,
    }, {
        title: '巨大发送容量',
        desc: '专业海外邮箱服务器',
        common: true,
        flagship: true,
    }, {
        title: '邮件营销数据',
        desc: '实时掌控邮件发送和阅读数据',
        common: true,
        flagship: true,
    }, {
        title: '网站体检',
        desc: '全面了解站点优化情况',
        common: true,
        flagship: true,
    }, {
        title: '竞品分析',
        desc: '洞察同行网站和数字营销数据',
        common: true,
        flagship: true,
    }, {
        title: '关键词优化',
        desc: '检索关键词数据和优化指标',
        common: true,
        flagship: true,
    }, {
        title: '网站流量分析',
        desc: '实时掌控网站流量和访客情况',
        common: true,
        flagship: true,
    }, {
        title: '排名跟踪',
        desc: '关注企业和同行网站搜索排名',
        common: true,
        flagship: true,
    }, {
        title: '客户资源管理',
        desc: '不同来源客户统一管理',
        common: true,
        flagship: true,
    }, {
        title: '精细化客户跟进',
        desc: '集中管理客户跟进情况',
        common: true,
        flagship: true,
    }, {
        title: '数据看板',
        desc: '掌握整体营销数字表现',
        common: true,
        flagship: true,
    }, {
        title: '多账号体系',
        desc: '支持多达10个子账号',
        common: true,
        flagship: true,
    }, {
        title: '标准广告投放方案',
        desc: '广告开户及投放运营',
        common: '单独购买',
        flagship: false,
    }, {
        title: '定制广告投放方案',
        desc: '',
        common: false,
        flagship: true,
    }, {
        title: '国家定向营销',
        desc: '针对特定国家的整合营销方案',
        common: false,
        flagship: true,
    }, {
        title: '品牌孵化',
        desc: '建立中企品牌出海宣传阵地',
        common: false,
        flagship: true,
    },
];

// 客户之声
export const Stores = [
    {
        title: '海外推广',
        content: '使用Google,Facebook推广引流对我们刚开展外贸业务的公司来说挑战很大，出海易上面的Google,Facebook广告投放，由资深专家掌舵，帮助我们制定了性价比超高的解决方案，广告投放后流量效果很满意。',
        name: '张总',
        brief: '某机械设备公司｜营销副总',
        img_url: storyImg1,
        imgsmall_url: storySmallImg1,
    }, {
        title: '独立站',
        content: '出海易平台上的独立站服务，让我印象深刻，从建站，商品管理，资质展示，企业邮箱注册到流量管理，轻松几步即可搞定，最打动我的是出海易还支持网站体检和SEO优化，使我们网站在竞争对手面前抢占流量先机。',
        name: '陈经理',
        brief: '某电子科技公司｜外贸经理',
        img_url: storyImg2,
        imgsmall_url: storySmallImg2,
    }, {
        title: '海关数据',
        content: '相比之前我们采买过的海关数据，出海易上面的海关数据，覆盖国家广，数据更新快，检索结果强而且还支持数据报告一键式导出，大大提升了我们海外业务商机匹配效率。',
        name: '何总监',
        brief: '某家具建材公司｜运营总监',
        img_url: storyImg3,
        imgsmall_url: storySmallImg3,
    }, {
        title: '数据看板',
        content: '我经常使用出海易平台里面的数据看板功能查看后台的数据统计和分析，简单易懂的图形图表能从不同维度向我展示外贸业务动态，帮助我把控公司业务节奏，及时调整营销策略。',
        name: '李主管',
        brief: '某安防企业｜外贸主管',
        img_url: storyImg4,
        imgsmall_url: storySmallImg4,
    },
];

export const ServiceText = {
    title: '链接出海易 掌握主动权',
    content: '秉承百度“用科技让复杂的世界更简单”的使命，出海易围绕出海企业的日常需求和业务场景，致力成为最懂企业需求的出海业务管家，助力中企品牌出海，在贸易新时代掌握主动权。',
    lin: {
        content: '了解更多',
        url: '/#joinus',
    },
};