import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "text"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("a", {
    class: _normalizeClass(['s-button', `s-btn-${_ctx.size}`]),
    onClick: _cache[0] || (_cache[0] = _withModifiers(
    //@ts-ignore
    (...args) => _ctx.jump && _ctx.jump(...args), ["stop"]))
  }, [_createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.text), 1), _ctx.showIcon ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    src: require('@/assets/img/arrow.png'),
    class: "icon-arrow",
    alt: "arrow"
  }, null, 8, _hoisted_2)) : _createCommentVNode("", true)], 2);
}